<template>
  <main>
    <form>
      <h1 class="pt-10 pb-16">
        Locataire
      </h1>

      <v-text-field
        v-model="tenant.firstname"
        label="Prénom"
        required
      ></v-text-field>

      <v-text-field
        v-model="tenant.lastname"
        label="Nom"
        required
      ></v-text-field>

      <v-text-field
        v-model="tenant.email"
        label="Email"
        required
      ></v-text-field>

      <v-text-field
        v-model="tenant.phone"
        label="Téléphone"
        required
      ></v-text-field>

      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedDateFormattedBirthdate"
            label="date de naissance"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="tenant.birthdate"
          @input="menu = false"
          locale="fr-fr"
        ></v-date-picker>
      </v-menu>

      <p v-show="tenant.birthdate">Age : {{ tenant.birthdate | age }} ans</p>
      <br />

      <h2>Activité</h2>
      <v-select
        v-model="tenant.professionalStatus"
        :items="professionalStatuses"
        item-text="label"
        item-value="id"
        label="Statuts professionnels"
      ></v-select>
      <br />

      <p>Revenu mensuel : {{ tenant.incomes }} </p>

      <br/>

      <div v-if="tenant.professionalStatus === 1">
        <v-text-field
          v-model="tenant.organizationName"
          label="Nom de l’employeur actuel"
          required
        ></v-text-field>

        <v-text-field
          v-model="tenant.profession"
          label="Votre profession"
          required
        ></v-text-field>

        <h4>Type de contrat</h4>

        <v-select
          v-model="tenant.contractType"
          :items="contractsTypes"
          item-text="label"
          item-value="id"
          label="Types de contrats"
        ></v-select>
      </div>

      <div v-if="tenant.professionalStatus === 2">
        <h2>Informations sur l'emploi actuel</h2>
        <v-text-field
          v-model="tenant.profession"
          label="Votre profession"
          required
        ></v-text-field>

        <v-text-field
          v-model="tenant.organizationName"
          label="nom de votre entreprise"
          required
        ></v-text-field>
      </div>

      <div v-else-if="tenant.professionalStatus === 3">
        <v-text-field
          v-model="tenant.schoolName"
          label="Nom de votre établissement actuel"
          required
        ></v-text-field>
      </div>

      <!-- <div v-if="tenant.professionalStatus === 5">
        <v-select
          v-model="tenant.compensationType"
          :items="compensationTypes"
          item-text="label"
          item-value="id"
          label="Types d'indemnités"
        ></v-select>
      </div> -->
      <br />

      <div>
        <h2>Mon garant</h2>

          <v-select
            v-model="tenant.guarantorType"
            :items="guarantorTypes"
            item-text="label"
            item-value="id"
            label="Types de garant"
          ></v-select>

          <div v-if="tenant.guarantorType === 2">
            <v-select
            v-model="tenant.guarantorCompany"
            :items="guarantorCompanies"
            item-text="label"
            item-value="id"
            label="Informations sur votre garant"
          ></v-select>
          </div>

          <div v-if="tenant.guarantorType === 3">
            <v-text-field
              v-model="tenant.guarantorRelationship"
              label="Lien de parenté"
              required
            ></v-text-field>
            <v-text-field
              v-model="tenant.guarantorIncomes"
              label="Revenu fiscal du garant"
              required
            ></v-text-field>
          </div>
      </div>


      <v-btn class="info" @click="submit()">
        Valider
      </v-btn>
    </form>
    <br />

    <div class="certifications-block" v-if="certifications.length">
      <h1>Certifications</h1>

      <ol>
        <ul v-if="certifications.includes('identity')">
          <h3>Certification d'identité <span class="tick">✅</span></h3>

          <!-- <p>{{tenant.identity ? tenant.identity : ''}}</p> -->

          <li>
            Statut ubble : <span>{{ tenant.ubbleIdentificationStatus }}</span>
          </li>
          <li>
            Score: <span>{{ tenant.ubbleIdentificationScore }}</span>
          </li>

          <li>Ubble json : <a target="blank" :href="tenant.identity && tenant.identity.response ? BaseUrl + tenant.identity.response : '' " alt="">Lien</a></li>
          <li>
            Pièce d'identité Recto :
            <span
              ><a
                target="blank"
                :href="tenant.identity && tenant.identity['signed-image-front-url'] ? BaseUrl + tenant.identity['signed-image-front-url'] : '' "
                alt=""
                >Lien</a
              ></span
            >
          </li>
          <li>
            Pièce d'identité Verso :
            <span
              ><a
                target="blank"
                :href="tenant.identity && tenant.identity['signed-image-back-url'] ?  BaseUrl + tenant.identity['signed-image-back-url'] : '' "
                alt=""
                >Lien</a
              ></span
            >
          </li>
          <li>
            Vidéo identité Recto:
            <span
              ><a
                target="blank"
                :href="tenant.identity && tenant.identity['signed-video-front-url'] ? BaseUrl +  tenant.identity['signed-video-front-url'] : '' "
                alt=""
                >Lien</a
              ></span
            >
          </li>
          <li>
            Vidéo identité Verso:
            <span
              ><a
                target="blank"
                :href="tenant.identity && tenant.identity['signed-video-back-url'] ? BaseUrl +  tenant.identity['signed-video-back-url'] : '' "
                alt=""
                >Lien</a
              ></span
            >
          </li>
          <li>
            Photo profil:
            <span
              ><a
                target="blank"
                :href="tenant.identity && tenant.identity['signed-image-url'] ? BaseUrl +  tenant.identity['signed-image-url'] : '' "
                alt=""
                >Lien</a
              ></span
            >
          </li>
          <li>
            Video profil :
            <span
              ><a
                target="blank"
                :href="tenant.identity && tenant.identity['signed-video-url'] ? BaseUrl +  tenant.identity['signed-video-url'] : '' "
                alt=""
                >Lien</a
              ></span
            >
          </li>
        </ul>

        <ul v-if="certifications.includes('budget')">
          <h3>Certification bancaire <span class="tick">✅</span></h3>

          <!-- <li>
            Revenu Moyen : <span>{{ budgets }}</span>
          </li> -->
          <li>
            Code Tink <span>{{ tenant.tinkCode ? tenant.tinkCode : "" }}</span>
          </li>

          <li>Identités Tink: 
            <span>
              <a
                  target="blank"
                  :href="tenant.budget && tenant.budget.identities ? BaseUrl +  tenant.budget.identities : '' "
                  alt=""
                  >Lien
              </a>
            </span>
          </li>

          <li>Catégories Tink: 
            <span>
              <a
                  target="blank"
                  :href="tenant.budget && tenant.budget.categories ? BaseUrl +  tenant.budget.categories :'' "
                  alt=""
                  >Lien
              </a>
            </span>
          </li>

          <li>Comptes Tink: 
            <span>
              <a
                  target="blank"
                  :href="tenant.budget && tenant.budget.accounts ? BaseUrl +  tenant.budget.accounts: '' "
                  alt=""
                  >Lien
              </a>
            </span>
          </li>

          <li>Investissement Tink: 
            <span>
              <a
                  target="blank"
                  :href="tenant.budget && tenant.budget.investments ? BaseUrl +  tenant.budget.investments : '' "
                  alt=""
                  >Lien
              </a>
            </span>
          </li>

         
         <li>Prêts Tink: 
            <span>
              <a
                  target="blank"
                  :href="tenant.budget && tenant.budget.loans ? BaseUrl +  tenant.budget.loans : '' "
                  alt=""
                  >Lien
              </a>
            </span>
          </li>
         
          <li>Transactions Tink:
            <span>
              <a
                  target="blank"
                  :href="tenant.budget && tenant.budget.transactions ? BaseUrl +  tenant.budget.transactions : '' "
                  alt=""
                  >Lien
              </a>
            </span>
          </li>
        </ul>

        <ul v-if="certifications.includes('tax')">
          <h3>Certification fiscale <span class="tick">✅</span></h3>

          <li>
            Référence de l'avis d'imposition :
            <span>{{ tenant.taxReference }}</span>
          </li>
          <li>
            Numéro d'identification Fiscale: <span>{{ tenant.taxNumber }}</span>
          </li>

          <li>
            Nom declarant 1:
            <span>{{ taxCertificationData.firstDeclarerLastname }}</span>
          </li>
          <li>
            Prénom declarant 1:
            <span>{{ taxCertificationData.firstDeclarerFirstnames }}</span>
          </li>
          <li>
            Nom de jeune fille declarant 1:
            <span>{{ taxCertificationData.firstDeclarerFirstnames }}</span>
          </li>
          <li>
            Date naissance declarant 1:
            <span>{{ taxCertificationData.firstDeclarerBirthdate }}</span>
          </li>
          <li>
            Nom declarant 2:
            <span>{{ taxCertificationData.secondDeclarerLastname }}</span>
          </li>
          <li>
            Prénom declarant 2:
            <span>{{ taxCertificationData.secondDeclarerFirstnames }}</span>
          </li>
          <li>
            Nom de jeune fille declarant 2:
            <span>{{ taxCertificationData.secondDeclarerBirthname }}</span>
          </li>
          <li>
            Date naissance declarant 2:
            <span>{{ taxCertificationData.secondDeclarerBirthdate }}</span>
          </li>
          <li>
            Adresse de l'avis d'imposition:
            <span>{{ taxCertificationData ? taxCertificationData.address[0] : 0 }}</span>
          </li>
          <li>
            Ville et Code postal de l'avis d'imposition:
            <span>{{ taxCertificationData ? taxCertificationData.address[1] : 0 }}</span>
          </li>
          <li>
            Date de mise en recouvrement de l'avis d'impot:
            <span>{{ taxCertificationData.collectingDate }}</span>
          </li>
          <li>
            Date d'établissement de l'impot:
            <span>{{ taxCertificationData.establishingDate }}</span>
          </li>
          <li>
            Nombre de part: <span>{{ taxCertificationData.shares }}</span>
          </li>
          <li>
            Nombre de personne à charge:
            <span>{{ taxCertificationData.dependents }}</span>
          </li>
          <li>
            Revenu brut global:
            <span>{{ taxCertificationData.grossRevenue }}</span>
          </li>
          <li>
            Revenu imposable:
            <span>{{ taxCertificationData.taxableIncome }}</span>
          </li>
          <li>
            Impot sur le revenu net avant correction:
            <span>{{ taxCertificationData.beforeCorrections }}</span>
          </li>
          <li>
            Montant de l'impot: <span>{{ taxCertificationData.amount }}</span>
          </li>
          <li>
            Revenu fiscal de reference:
            <span>{{ taxCertificationData.incomeReference }}</span>
          </li>
        </ul>

        <ul v-if="certifications.includes('guarantorTax')">
          <h3>Certification fiscale du garant <span class="tick">✅</span></h3>

          <li>
            Référence de l'avis d'imposition :
            <span> {{ tenant.guarantorTaxReference }}</span>
          </li>
          <li>
            Numéro d'identification Fiscale:
            <span>{{ tenant.guarantorTaxNumber }}</span>
          </li>

          <li>
            Nom declarant 1:
            <span>{{
              GuarantorTaxCertificationData.firstDeclarerLastname
            }}</span>
          </li>
          <li>
            Prénom declarant 1:
            <span>{{
              GuarantorTaxCertificationData.firstDeclarerFirstnames
            }}</span>
          </li>
          <li>
            Nom de jeune fille declarant 1:
            <span>
              {{ GuarantorTaxCertificationData.firstDeclarerBirthname }}</span
            >
          </li>
          <li>
            Date naissance declarant 1:
            <span>{{
              GuarantorTaxCertificationData.firstDeclarerBirthdate
            }}</span>
          </li>
          <li>
            Nom declarant 2:
            <span>{{
              GuarantorTaxCertificationData.secondDeclarerLastname
            }}</span>
          </li>
          <li>
            Prénom declarant 2:
            <span>{{
              GuarantorTaxCertificationData.secondDeclarerFirstnames
            }}</span>
          </li>
          <li>
            Nom de jeune fille declarant 2:
            <span>{{
              GuarantorTaxCertificationData.secondDeclarerBirthname
            }}</span>
          </li>
          <li>
            Date naissance declarant 2:
            <span>{{
              GuarantorTaxCertificationData.secondDeclarerBirthdate
            }}</span>
          </li>
          <li>
            Adresse de l'avis d'imposition:
            <span> {{ GuarantorTaxCertificationData ? GuarantorTaxCertificationData.address[0] : "" }}</span>
          </li>
          <li>
            Ville et Code postal de l'avis d'imposition:
            <span>{{ GuarantorTaxCertificationData ? GuarantorTaxCertificationData.address[1] : ""  }}</span>
          </li>
          <li>
            Date de mise en recouvrement de l'avis d'impot:
            <span> {{ GuarantorTaxCertificationData.collectingDate }}</span>
          </li>
          <li>
            Date d'établissement de l'impot:
            <span>{{ GuarantorTaxCertificationData.establishingDate }}</span>
          </li>
          <li>
            Nombre de part:
            <span>{{ GuarantorTaxCertificationData.shares }}</span>
          </li>
          <li>
            Nombre de personne à charge:
            <span>{{ GuarantorTaxCertificationData.dependents }}</span>
          </li>
          <li>
            Revenu brut global:
            <span>{{ GuarantorTaxCertificationData.grossRevenue }}</span>
          </li>
          <li>
            Revenu imposable:
            <span>{{ GuarantorTaxCertificationData.taxableIncome }}</span>
          </li>
          <li>
            Impot sur le revenu net avant correction:
            <span>{{ GuarantorTaxCertificationData.beforeCorrections }}</span>
          </li>
          <li>
            Montant de l'impot:
            <span>{{ GuarantorTaxCertificationData.amount }}</span>
          </li>
          <li>
            Revenu fiscal de reference:
            <span>{{ GuarantorTaxCertificationData.incomeReference }}</span>
          </li>
        </ul>
      </ol>
    </div>
    <br />

    <!-- <h1>Revenus récurrents</h1>
    <v-data-table
      :headers="recurringIncomesHeader"
      :items="recurringIncomes"
      :hide-default-footer="true"
      dense
      class="mb-10"
    /> -->
  </main>
</template>

<script>
import axios from "axios";
import axiosGetListData from "@/apiCall/axiosGetList.js";

export default {
  data: () => ({
    BaseUrl: process.env.VUE_APP_BASEURL,
    tenant: {},
    professionalStatuses: [],
    contractsTypes: [],
    // schoolLevels: [],
    guarantorTypes: [],
    guarantorCompanies: [],
    compensationTypes: [],
    recurringIncomes: [],
    recurringIncomesHeader: [
      { text: "Id", value: "id", sortable: false },
      { text: "Type", value: "type", sortable: false },
      { text: "Montant", value: "amount", sortable: false },
    ],
    certifications: [],
    taxCertificationData: {},
    idCertificationData: {},
    budgetCertificationData: {},
    GuarantorTaxCertificationData: {},
    menu: false,
    menu2: false,
    menu3: false,
  }),

  mounted() {
    axios
      .get(
        process.env.VUE_APP_APIURL +
          "/rentalFilesTenants/" +
          this.$route.params.tenantUid,
        {
          headers: {
            "Access-Token": localStorage.getItem("token"),
          },
         params : {
							expand : "identity,budget"
					}
        }
      )
      .then((res) => {
        this.tenant = Object.assign(
          res.data,
          {
            birthdate: res.data.birthdate ? new Date(res.data.birthdate).toISOString().substr(0,10) : ''
          },
        );

        if (this.tenant.ubbleIdentificationScore == 1) {
          this.certifications.push("identity");

          axios
            .get(
              process.env.VUE_APP_APIURL +
                "/rentalFilesTenantsIdentityCertifications",
              {
                headers: {
                  "Access-Token": localStorage.getItem("token"),
                },
                params: {
                  rentalFileTenantUid: this.$route.params.tenantUid,
                },
              }
            )
            .then((res) => {
              this.idCertificationData = res.data;
            });
        }

        if (this.tenant.tinkCode != null) {
          this.certifications.push("budget");
        }

        if (this.tenant.taxReference != null) {
          this.certifications.push("tax");

          axios
            .get(
              process.env.VUE_APP_APIURL +
                "/rentalFilesTenantsTaxCertifications",
              {
                headers: {
                  "Access-Token": localStorage.getItem("token"),
                },
                params: {
                  rentalFileTenantUid: this.$route.params.tenantUid,
                },
              }
            )
            .then((res) => {
              this.taxCertificationData = res.data;
            });
        }

        if (this.tenant.guarantorTaxReference != null) {
          this.certifications.push("guarantorTax");

          axios
            .get(
              process.env.VUE_APP_APIURL +
                "/rentalFilesTenantsGuarantorsTaxCertifications",
              {
                headers: {
                  "Access-Token": localStorage.getItem("token"),
                },
                params: {
                  rentalFileTenantUid: this.$route.params.tenantUid,
                },
              }
            )
            .then((res) => {
              this.GuarantorTaxCertificationData = res.data;
            });
        }
      });

    axiosGetListData(
      this,
      "/rentalFilesTenantsProfessionalStatuses",
      "professionalStatuses"
    );

    axiosGetListData(
      this,
      "/rentalFilesTenantsContractsTypes",
      "contractsTypes"
    );

    // axiosGetListData(this, "/rentalFilesTenantsSchoolLevels", "schoolLevels");
    axiosGetListData(this, "/rentalFilesTenantsGuarantorsTypes", "guarantorTypes");
    axiosGetListData(this, "/rentalFilesTenantsGuarantorsCompanies", "guarantorCompanies");

    axiosGetListData(
      this,
      "/rentalFilesTenantsCompensationsTypes",
      "compensationTypes"
    );

    

    axios
      .get(process.env.VUE_APP_APIURL + "/rentalFilesTenantsRecurringIncomes", {
        headers: {
          "Access-Token": localStorage.getItem("token"),
        },
        params: {
          rentalFileTenantUid: this.$route.params.tenantUid,
          expand: "type",
        },
      })
      .then((res) => {
        this.recurringIncomes = res.data.map((item) =>
          Object.assign(item, { type: `${item.type.id} ${item.type.label}` })
        );
      });
  },

  computed: {
    // budgets() {
    //   return Math.round(
    //     (this.tenant.incomes1 + this.tenant.incomes2 + this.tenant.incomes3) /
    //       3 +
    //       this.tenant.unusualIncomes / 12 +
    //       (this.recurringIncomes.length
    //         ? this.recurringIncomes.reduce((a, b) => a + b, 0) /
    //           this.recurringIncomes.length
    //         : 0)
    //   );
    // },

    computedDateFormattedBirthdate () {
        return this.formatDate(this.tenant.birthdate)
      },
  },

  methods: {
    // getPreviousMonth(m) {
    //   const d = new Date();test
    //   const formatter = new Intl.DateTimeFormat("fr", { month: "long" }).format(
    //     d.setMonth(d.getMonth() - m)
    //   );

    //   if (
    //     this.tenant.professionalStatus === 4 ||
    //     this.tenant.professionalStatus === 5
    //   ) {
    //     return `Votre indemnité de ${formatter}`;
    //   }
    //   return `Votre revenu de ${formatter}`;
    // },

    formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },

    submit() {
      axios
        .put(
          process.env.VUE_APP_APIURL + "/rentalFilesTenants/" + this.tenant.uid,
          {
            firstname: this.tenant.firstname
              ? this.tenant.firstname
              : undefined,
            lastname: this.tenant.lastname ? this.tenant.lastname : undefined,
            email: this.tenant.email ? this.tenant.email : undefined,
            phone: this.tenant.phone ? this.tenant.phone : undefined,
            birthdate: this.tenant.birthdate
              ? new Date(this.tenant.birthdate)
              : undefined,
            professionalStatus:
              this.tenant.professionalStatus != null
                ? this.tenant.professionalStatus
                : undefined,
            contractType:
              this.tenant.contractType != null
                ? this.tenant.contractType
                : undefined,
            profession: this.tenant.profession
              ? this.tenant.profession
              : undefined,
            organizationName: this.tenant.organizationName
              ? this.tenant.organizationName
              : undefined,
            schoolName: this.tenant.schoolName
              ? this.tenant.schoolName
              : undefined,
            compensationType:
              this.tenant.compensationType != null
                ? this.tenant.compensationType
                : undefined,
            guarantorRelationship: this.tenant.guarantorRelationship
              ? this.tenant.guarantorRelationship
              : undefined,
            guarantorIncomes: this.tenant.guarantorIncomes
              ? this.tenant.guarantorIncomes
              : undefined,
            guarantorCompany: this.tenant.guarantorCompany
              ? this.tenant.guarantorCompany
              : undefined,
            guarantorType: this.tenant.guarantorType
              ? this.tenant.guarantorType
              : undefined,
          },
          {
            headers: {
              "Access-Token": localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            document.location.reload();
          }
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
  },
};
</script>

<style lang="scss">
main {
  /* max-width: 250px;*/
  margin: auto;
}
.certifications-block {
  margin-bottom: 50px;
  h1 {
    text-align: center;
    padding: 25px;
  }
  h3 {
    text-align: center;
    padding: 100px 0 25px 0;
    .tick {
      padding: 0 15px;
       text-decoration:  !important;
    }
  }
  ol {
    ul {
      list-style-type: disc;
      padding: 0;
      li {
        padding: 10px 0;
        span{
          font-weight: 800;
        }
      }
    }
  }
}
</style>
