import axios from 'axios'


// export default function axiosGetSimpleData(object){
  
//   const parameters = [
//     { address: '/propertiesTypes', attribute: 'items' },
//   ]

//   parameters.forEach(parameter => {
//     axios(process.env.VUE_APP_APIURL + parameter.address)
//       .then(response => {
//           object[parameter.attribute] = response.data
//     })
//   })
// }

export default function axiosGetListData(data, url, attribute, options){

    axios(process.env.VUE_APP_APIURL + url, options)
      .then(response => {
          data[attribute] = response.data
    })
    .catch(err => {
    console.log(err.response.data)
  })
}